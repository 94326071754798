import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
import Divider from '@nib-components/divider';
import Link from '@nib-components/link';
import { Box, Tiles, Margin } from '@nib/layout';
import { DownloadSystemIcon } from '@nib/icons';
import { BRAND_ASSETS_BASEURL, NIB_CORE_LOGOS_PACK_DOWNLOAD_LINK, NIB_FIRST_CHOICE_LOGOS_PACK_DOWNLOAD_LINK, NIB_FOUNDATION_LOGOS_PACK_DOWNLOAD_LINK, NIB_REWARDS_LOGOS_PACK_DOWNLOAD_LINK, NIB_THRIVE_LOGOS_PACK_DOWNLOAD_LINK, STAR_SYMBOL_DOWNLOAD_LINK, ARROW_SYMBOL_DOWNLOAD_LINK } from '../../src/constants.js';
import { ColorDot } from '../../src/components/colors/ColorPalette.js';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PrimaryButton = makeShortcode("PrimaryButton");
const SecondaryButton = makeShortcode("SecondaryButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "primary-logo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#primary-logo",
        "aria-label": "primary logo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Primary logo`}</h2>
    <p>{`Our Primary Logo is the central element of our brand system. It is a lockup made up of our star symbol and wordmark.`}</p>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={0} align="center" mdxType="Tiles">
  <Box background="brightGreen" paddingVertical={{
        xs: 7,
        lg: 10
      }} mdxType="Box">
    <figure>
      <img src="/f12809dd0506d736d424c8696b2939d7/nib_RGB_PrimaryLogo_TrueGreen.svg" width="300" alt="nib primary logo in True Green" />
    </figure>
  </Box>
  <Box background="trueGreen" paddingVertical={{
        xs: 7,
        lg: 10
      }} mdxType="Box">
    <figure>
      <img src="/120e8bbbf838c39d025e58415d3f9163/nib_RGB_PrimaryLogo_BrightGreen.svg" width="300" alt="nib primary logo in Bright Green" />
    </figure>
  </Box>
    </Tiles>
    <p>{`The Primary logo should be used for all nib group communications including, but not limited to, employee correspondence, internal documents, corporate stationery and printed customer communications e.g. letters and forms.`}</p>
    <p>{`The Primary logo can also to be used for `}<a parentName="p" {...{
        "href": "/brand/nib-co-brand-logos/"
      }}>{`co-branding`}</a>{` purposes in regards to corporate, sponsorship and partnership relationships.`}</p>
    <h3 {...{
      "id": "clear-space-and-minimum-size",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#clear-space-and-minimum-size",
        "aria-label": "clear space and minimum size permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Clear space and minimum size`}</h3>
    <p>{`As a key asset of the toolkit, we must work to maintain our logo's integrity and clarity in every application.`}</p>
    <p>{`Clear space is given to allow breathing room around the logo and avoid third-party elements, such as typography and graphic devices to obstruct the logo. The clear space is measured by the width of the letter 'i' in nib and should evenly surround all sides of the logo.`}</p>
    <p>{`For optimum legibility please adhere to the minimum size measurements of `}<inlineCode parentName="p">{`25mm`}</inlineCode>{` wide for print, and `}<inlineCode parentName="p">{`100px`}</inlineCode>{` for digital applications.`}</p>
    <h3 {...{
      "id": "primary-logo-colour-combinations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#primary-logo-colour-combinations",
        "aria-label": "primary logo colour combinations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Primary logo colour combinations`}</h3>
    <p>{`Our primary colour combinations are how the brand should be shown in most cases for brand recognition. For high-level brand comms with the most aspiration, True Green `}<ColorDot background="trueGreen" mdxType="ColorDot" />{` on Bright Green `}<ColorDot background="brightGreen" mdxType="ColorDot" />{` and Bright Green `}<ColorDot background="brightGreen" mdxType="ColorDot" />{` on True Green `}<ColorDot background="trueGreen" mdxType="ColorDot" />{` are our preference.`}</p>
    <p>{`In most cases, the brand will live on Clear White `}<ColorDot background="white" mdxType="ColorDot" />{` backgrounds, so in this case, the best colour combination is True Green `}<ColorDot background="trueGreen" mdxType="ColorDot" />{` on Clear White `}<ColorDot background="white" mdxType="ColorDot" />{`.`}</p>
    <p>{`When designing, consider the output and context of the design and where each of these three colour combinations would be better fit for purpose.`}</p>
    <h3 {...{
      "id": "logos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#logos",
        "aria-label": "logos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Logos`}</h3>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={4} align="center" mdxType="Tiles">
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/f12809dd0506d736d424c8696b2939d7/nib_RGB_PrimaryLogo_TrueGreen.svg" width="300" alt="nib primary logo in True Green" />
      <figcaption>
        nib primary logo in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" padding={2} mdxType="Box">
    <figure>
      <img src="/120e8bbbf838c39d025e58415d3f9163/nib_RGB_PrimaryLogo_BrightGreen.svg" width="300" alt="nib primary logo in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib primary logo in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/ee97730088e69e2761acc63267c90ca5/nib_RGB_PrimaryLogo_Black.svg" width="300" alt="nib primary logo in black" />
      <figcaption>
        nib primary logo in Black <ColorDot background="black" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" padding={2} mdxType="Box">
    <figure>
      <img src="/6894edc6c9059b500f10d27fa9bf3dbb/nib_RGB_PrimaryLogo_ClearWhite.svg" width="300" alt="nib primary logo in white" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib primary logo in White <ColorDot background="white" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
    </Tiles>
    <h3 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Download`}</h3>
    <p>{`The logo pack contains the nib brand logos shown above and includes assets for use in print and on screen.`}</p>
    <p>{`For any printed materials like brochures, flyers, magazine/newspaper and promotional items please use the file formats `}<strong parentName="p">{`eps, jpg`}</strong>{` and `}<strong parentName="p">{`pdf`}</strong>{`.`}</p>
    <p>{`For screen display like plasmas, email, web and TV please use the file formats `}<strong parentName="p">{`svg, jpg`}</strong>{` and `}<strong parentName="p">{`png`}</strong>{`.`}</p>
    <PrimaryButton href={NIB_CORE_LOGOS_PACK_DOWNLOAD_LINK} download="nib-core-brand-logos.zip" mdxType="PrimaryButton">
  Download logo pack
    </PrimaryButton>
    <h2 {...{
      "id": "brand-elements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#brand-elements",
        "aria-label": "brand elements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Brand elements`}</h2>
    <h3 {...{
      "id": "star-symbol",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#star-symbol",
        "aria-label": "star symbol permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Star symbol`}</h3>
    <p>{`The star symbol encompasses our brand strategy visually and we use it on its own as a graphic device within applications.`}</p>
    <p>{`Please refer to `}<a parentName="p" {...{
        "href": "/brand/nib-logos/"
      }}>{`core nib logo usage rules`}</a>{` when using the star symbol.`}</p>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={4} align="center" mdxType="Tiles">
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/80767a249fd934e5c27b4954525f87b5/nib_RGB_Symbol_TrueGreen.svg" width="300" alt="nib star symbol in True Green" />
      <figcaption>
        nib star symbol in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" padding={2} mdxType="Box">
    <figure>
      <img src="/737d3416ed4f3fdb045d69c301d0bdbe/nib_RGB_Symbol_BrightGreen.svg" width="300" alt="nib star symbol in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib star symbol in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
    </Tiles>
    <h4 {...{
      "id": "download-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#download-1",
        "aria-label": "download 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Download`}</h4>
    <p>{`The star symbol pack contains the assets in multiple formats for use on both print and screen.`}</p>
    <p>{`For any printed materials like brochures, flyers, magazine/newspaper and promotional items please use the file formats `}<strong parentName="p">{`eps, jpg`}</strong>{` and `}<strong parentName="p">{`pdf`}</strong>{`.`}</p>
    <p>{`For screen display like plasmas, email, web and TV please use the file formats `}<strong parentName="p">{`svg, jpg`}</strong>{` and `}<strong parentName="p">{`png`}</strong>{`.`}</p>
    <Box padding={4} align="center" mdxType="Box">
  <SecondaryButton href={STAR_SYMBOL_DOWNLOAD_LINK} download="star-symbol.zip" mdxType="SecondaryButton">
    Download star symbol pack
  </SecondaryButton>
    </Box>
    <h3 {...{
      "id": "graphic-arrow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#graphic-arrow",
        "aria-label": "graphic arrow permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Graphic Arrow`}</h3>
    <p>{`A key graphic element for our brand is our right-angle arrow, which is found in our brand symbol.`}</p>
    <p>{`It is used to encourage action and promote our optimistic upward trajectory. In application it can be used as a bold graphic or as a smaller feature.`}</p>
    <p>{`The arrow `}<strong parentName="p">{`always`}</strong>{` points north-east and should never be rotated.`}</p>
    <p>{`The arrow doesn’t always need to point at content on the page, but can help to direct attention and focus.`}</p>
    <p>{`Please refer to `}<a parentName="p" {...{
        "href": "/brand/nib-logos/"
      }}>{`core nib logo usage rules`}</a>{` when using the graphic arrow symbol.`}</p>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={4} align="center" mdxType="Tiles">
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/a17b5607b7e538f0534370349d9fa370/nib_RGB_Arrow_TrueGreen.svg" width="300" alt="nib arrow symbol in True Green" />
      <figcaption>
        nib arrow symbol in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" padding={2} mdxType="Box">
    <figure>
      <img src="/57d6983d502bcab65237af28c966cbe4/nib_RGB_Arrow_BrightGreen.svg" width="300" alt="nib arrow symbol in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib arrow symbol in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" /> .
      </figcaption>
    </figure>
  </Box>
    </Tiles>
    <h4 {...{
      "id": "download-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#download-2",
        "aria-label": "download 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Download`}</h4>
    <p>{`The graphic arrow pack contains the assets in multiple formats for use on both print and screen.`}</p>
    <p>{`For any printed materials like brochures, flyers, magazine/newspaper and promotional items please use the file formats `}<strong parentName="p">{`eps, jpg`}</strong>{` and `}<strong parentName="p">{`pdf`}</strong>{`.`}</p>
    <p>{`For screen display like plasmas, email, web and TV please use the file formats `}<strong parentName="p">{`svg, jpg`}</strong>{` and `}<strong parentName="p">{`png`}</strong>{`.`}</p>
    <Box padding={4} align="center" mdxType="Box">
  <SecondaryButton href={ARROW_SYMBOL_DOWNLOAD_LINK} download="arrow-symbol.zip" mdxType="SecondaryButton">
    Download arrow symbol pack
  </SecondaryButton>
    </Box>
    <h2 {...{
      "id": "nib-complementary-brand-logos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nib-complementary-brand-logos",
        "aria-label": "nib complementary brand logos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`nib complementary brand logos`}</h2>
    <p>{`These logos are complementary to the nib brand and identify a specific 'product or service' provided under the nib Group. The Subsidiary Brand includes the use of the nib core logo and is to be used within all material where relevant when communicating to consumers about the 'product or service' offering that it represents.`}</p>
    <p>{`The logo packs contain the nib complementary brand logos shown below and includes assets for use in print and on screen.`}</p>
    <p>{`For any printed materials like brochures, flyers, magazine/newspaper and promotional items please use the file formats `}<strong parentName="p">{`eps, jpg`}</strong>{` and `}<strong parentName="p">{`pdf`}</strong>{`.`}</p>
    <p>{`For screen display like plasmas, email, web and TV please use the file formats `}<strong parentName="p">{`svg, jpg`}</strong>{` and `}<strong parentName="p">{`png`}</strong>{`.`}</p>
    <h3 {...{
      "id": "nib-first-choice-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#nib-first-choice-network",
        "aria-label": "nib first choice network permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`nib First Choice network`}</h3>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={4} align="center" mdxType="Tiles">
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/c02e613cfdfc40b12f6a3ce1e80b5b2c/nib_First_Choice_HORIZ_True_Green_RGB.svg" width="300" alt="nib First Choice network horizontal logo in True Green" />
      <figcaption>
        nib First Choice network horizontal logo in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" padding={2} mdxType="Box">
    <figure>
      <img src="/0318f4e415cd96f1bf41e93f50043911/nib_First_Choice_HORIZ_Bright_Green_Rev_RGB.svg" width="300" alt="nib First Choice network horizontal logo in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib First Choice network horizontal logo in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" /> and White <ColorDot background="white" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/f80a138ba45feff98696db22f479aeda/nib_First_Choice_STACK_True_Green_RGB.svg" width="300" alt="nib First Choice network stacked logo in True Green" />
      <figcaption>
        nib First Choice network stacked logo in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" color="white" padding={2} mdxType="Box">
    <figure>
      <img src="/cec56731f121ee899b1a24a1bb74828c/nib_First_Choice_STACK_Bright_Green_Rev_RGB.svg" width="300" alt="nib First Choice network stacked logo in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib First Choice network stacked logo in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" /> and White <ColorDot background="white" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
    </Tiles>
    <Box padding={4} align="center" mdxType="Box">
  <SecondaryButton href={NIB_FIRST_CHOICE_LOGOS_PACK_DOWNLOAD_LINK} download="nib-first-choice-logos.zip" mdxType="SecondaryButton">
    Download nib First Choice logo pack
  </SecondaryButton>
    </Box>
    <h3 {...{
      "id": "nib-foundation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#nib-foundation",
        "aria-label": "nib foundation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`nib Foundation`}</h3>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={4} align="center" mdxType="Tiles">
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/c875d0329deac9fd689bb2a5eef79a2c/nib_RGB_foundation_HORIZ.svg" width="300" alt="nib Foundation horizontal logo in True Green" />
      <figcaption>
        nib Foundation horizontal logo in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" padding={2} mdxType="Box">
    <figure>
      <img src="/389c82d1c1ddf089d5dac73f422894ef/nib_RGB_foundation_HORIZ_GW-01.svg" width="300" alt="nib Foundation horizontal logo in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib Foundation horizontal logo in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" /> and White <ColorDot background="white" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/2955a4fb95fffedb6ebfe69aa63b6cf4/nib_RGB_foundation_STACK.svg" width="300" alt="nib Foundation stacked logo in True Green" />
      <figcaption>
        nib Foundation stacked logo in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" color="white" padding={2} mdxType="Box">
    <figure>
      <img src="/f71d39e700075b2bbbd501c13518e13f/nib_RGB_foundation_STACK_GW-01.svg" width="300" alt="nib Foundation stacked logo in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib Foundation stacked logo in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" /> and White <ColorDot background="white" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
    </Tiles>
    <Box padding={4} align="center" mdxType="Box">
  <SecondaryButton href={NIB_FOUNDATION_LOGOS_PACK_DOWNLOAD_LINK} download="nib-foundation-logos.zip" mdxType="SecondaryButton">
    Download nib Foundation logo pack
  </SecondaryButton>
    </Box>
    <h3 {...{
      "id": "nib-rewards",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#nib-rewards",
        "aria-label": "nib rewards permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`nib Rewards`}</h3>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={4} align="center" mdxType="Tiles">
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/82bd7b38bf4371582469e0e642a0fd12/nib_RGB_Rewards_HORIZ.svg" width="300" alt="nib Rewards horizontal logo in True Green" />
      <figcaption>
        nib Rewards horizontal logo in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" padding={2} mdxType="Box">
    <figure>
      <img src="/9a898714339524148d479b5cacd3ad0e/nib_RGB_Rewards_HORIZ_GW-01.svg" width="300" alt="nib Rewards horizontal logo in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib Rewards horizontal logo in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" /> and White <ColorDot background="white" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/9807448e25d06dc485dd30193d6d8caf/nib_RGB_Rewards_STACK.svg" width="300" alt="nib Rewards stacked logo in True Green" />
      <figcaption>
        nib Rewards stacked logo in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" color="white" padding={2} mdxType="Box">
    <figure>
      <img src="/215ccc0e676517762e9b74d4fb64523a/nib_RGB_Rewards_STACK_GW-01.svg" width="300" alt="nib Rewards stacked logo in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib Rewards stacked logo in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" /> and White <ColorDot background="white" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
    </Tiles>
    <Box padding={4} align="center" mdxType="Box">
  <SecondaryButton href={NIB_REWARDS_LOGOS_PACK_DOWNLOAD_LINK} download="nib-rewards-logos.zip" mdxType="SecondaryButton">
    Download nib Rewards logo pack
  </SecondaryButton>
    </Box>
    <h3 {...{
      "id": "nib-thrive",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#nib-thrive",
        "aria-label": "nib thrive permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`nib Thrive`}</h3>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={4} align="center" mdxType="Tiles">
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/06c9405070df4096920fbf2a601e0130/nib_RGB_Thrive_HORIZ_TG.svg" width="300" alt="nib Thrive horizontal logo in True Green" />
      <figcaption>
        nib Thrive horizontal logo in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" padding={2} mdxType="Box">
    <figure>
      <img src="/c62727ed41db53663d3119886b9ce289/nib_RGB_Thrive_HORIZ_BGW.svg" width="300" alt="nib Thrive horizontal logo in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib Thrive horizontal logo in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" /> and White <ColorDot background="white" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box padding={2} mdxType="Box">
    <figure>
      <img src="/99c5377fc7e1cbc334af30a4688bfc5d/nib_RGB_Thrive_STACKED_TG.svg" width="300" alt="nib Thrive stacked logo in True Green" />
      <figcaption>
        nib Thrive stacked logo in True Green <ColorDot background="trueGreen" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
  <Box background="trueGreen" color="white" padding={2} mdxType="Box">
    <figure>
      <img src="/642e710a513e0baab40e2f4b90ff5e9c/nib_RGB_Thrive_STACKED_BGW.svg" width="300" alt="nib Thrive stacked logo in Bright Green" />
      <figcaption style={{
            color: '#ffffff'
          }}>
        nib Thrive stacked logo in Bright Green <ColorDot background="brightGreen" mdxType="ColorDot" /> and White <ColorDot background="white" mdxType="ColorDot" />.
      </figcaption>
    </figure>
  </Box>
    </Tiles>
    <Box padding={4} align="center" mdxType="Box">
  <SecondaryButton href={NIB_THRIVE_LOGOS_PACK_DOWNLOAD_LINK} download="nib-thrive-logos.zip" mdxType="SecondaryButton">
    Download nib Thrive logo pack
  </SecondaryButton>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      